<!-- =========================================================================================
  File Name: UserView.vue
  Description: User View page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="page-user-view">

    <vs-alert color="danger" title="User Not Found" :active.sync="user_not_found">
      <span>User record with id: {{ $route.params.userId }} not found. </span>
      <span>
        <span>Check </span><router-link :to="{name:'page-user-list'}" class="text-inherit underline">All Users</router-link>
      </span>
    </vs-alert>

    <div id="user-data" >

      <vx-card class="mb-base">

        <div class="vx-card__title mb-4">
          <h4 class="">{{user_data.profileCreatedBy | capitalize}} account</h4>
          </div>
        <!-- Avatar -->
        <div class="vx-row">

          <!-- Avatar Col -->
          <div class="vx-col" id="avatar-col">
            <div class="img-container mb-4" v-if="user_data.profileImageUrl">
              <img :src="user_data.profileImageUrl" class="imgfit" />
            </div>
            <div class="img-container mb-4 mt-1" v-else>
              <img src="https://freshclinics-bucket.s3.ap-southeast-2.amazonaws.com/undefined/k3r1nm7bpf921595963499155.png" class="imgfit" />
            </div>
          </div>

          <!-- Information - Col 1 -->
          <div class="vx-col flex-1" id="account-info-col-1">
            <table>
              <tr>
                <td class="font-semibold">Name</td>
                <td>{{ user_data.fullName }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Email</td>
                <td>{{ user_data.email }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Contact Number</td>
                <td>{{ user_data.contactNumber || "N/A" }}</td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 1 -->

          <!-- Information - Col 2 -->
          <div class="vx-col flex-1" id="account-info-col-2">
            <table>
              <tr>
                <td class="font-semibold">Status</td>
                <td>{{ user_data.status }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Profile Completed</td>
                <td>
                    <vs-icon v-if="user_data.profileCreated" icon="check_circle" size="small" color="#a5c6fa"></vs-icon>
                    <vs-icon v-else icon="cancel" size="small" color="#fa6e6e"></vs-icon>
                </td>
              </tr>
              <tr>
                <td class="font-semibold">Address</td>
                <td>{{user_data.address && user_data.address.displayAddress ? user_data.address.displayAddress :  "N/A" }}</td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 2 -->
          <div class="vx-col w-full flex" id="account-manage-buttons">
            <vs-button icon-pack="feather" icon="icon-edit" class="mr-4" :to="{name: 'EditUserDetails', params: { userId: $route.params.userId }}">Edit</vs-button>
            <vs-button type="border" v-if="user_data.status ==='Enabled'" color="danger" icon-pack="feather" icon="icon-slash" @click="confirmDeleteRecord">Disable</vs-button>
          </div>

        </div>

      </vx-card>
<div class="vx-row">
        <div v-for="student in user_data.students" :key="student" class="vx-col lg:w-1/2 w-full">
          <vx-card v-if="student && student.name" title="Student Information" class="mb-base">
            <table>
              <tr>
                <td class="font-semibold">Name</td>
                <td>{{ student.name ? student.name : "N/A"}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Languages</td>
                <td>{{ student.languageName ? student.languageName.join(", ") : "N/A"}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Level of Study</td>
                <td>{{ student.levelOfStudy || "N/A"}}</td>
              </tr>
            </table>
          </vx-card>
        </div>
</div>
      <!-- <div class="vx-row">
        <div class="vx-col lg:w-1/2 w-full">
          <vx-card title="Tutoring Information" class="mb-base">
            <table>
              <tr>
                <td class="font-semibold">Tutoring Location</td>
                <td>{{ user_data.tutoringLocation ? user_data.tutoringLocation.displayAddress : "N/A" }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Languages</td>
                <td>{{ user_data.languageName ? user_data.languageName.join(", ") : "N/A"}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Subjects</td>
                <td>{{ user_data.subjectName ? user_data.subjectName.join(", ") : "N/A"}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Rate</td>
                <td> {{"$"+ user_data.rate || "N/A"}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Average Ratings</td>
                <td>{{user_data.averageRating}}<span style="font-size:0.7em;">({{user_data.totalRatingsCount}})</span></td>
              </tr>
            </table>
          </vx-card>
          <vx-card title="Work Experiences" class="mb-base">
            <p v-if="user_data.workExperiences < 1">N/A</p>

            <table v-for="(workExperience, index) in user_data.workExperiences" :key="workExperience">
              <p class="font-bold"><u>Work Experience #{{index + 1}}</u></p>
              <tr>
                <td class="font-semibold">Company</td>
                <td>{{ workExperience.company }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Title</td>
                <td>{{ workExperience.title }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Started At</td>
                <td>{{ workExperience.startedAt ? moment(workExperience.startedAt).format('DD/MM/YYYY') : 'N/A' }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Ended At</td>
                <td>{{ workExperience.endedAt ? moment(workExperience.endedAt).format('DD/MM/YYYY') : 'N/A' }}</td>
              </tr>
            </table>
          </vx-card>
        </div>
<div class="vx-col lg:w-1/2 w-full">
          <vx-card title="Qualifications" class="mb-base">
            <p v-if="user_data.qualifications.length < 1">N/A</p>
            <table v-for="(qualification, index) in user_data.qualifications" :key="qualification">
              <p class="font-bold"><u>Qualification #{{index + 1}}</u></p>
              <tr>
                <td class="font-semibold">Institute</td>
                <td>{{ qualification.institute }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Title</td>
                <td>{{ qualification.title }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Subject</td>
                <td>{{ qualification.subject }}</td>
              </tr>
              <tr>
                <td class="font-semibold">atar</td>
                <td>{{ qualification.atar }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Started At</td>
                <td>{{ qualification.startedAt ? moment(qualification.startedAt).format('DD/MM/YYYY') : 'N/A'}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Ended At</td>
                <td>{{ qualification.endedAt ? moment(qualification.endedAt).format('DD/MM/YYYY') : 'N/A'}}</td>
              </tr>
            </table>
          </vx-card>
</div>
      </div> -->

    </div>
  </div>
</template>

<script>
import moduleUserManagement from '@/store/user-management/moduleUserManagement.js'
import { mapActions } from 'vuex';
import moment from 'moment'
export default {
  data() {
    return {
      user_data: {},
      user_not_found: false,
    }
  },
  computed: {

  },
  methods: {
    ...mapActions('user', ['fetchUserById']),
    ...mapActions('tutor', ['fetchTutorById','updateTutorById']),
    moment(date){
      return moment(date)
    },
    getTutorDetails() {
      let self = this;
      this.fetchUserById(self.$route.params.userId).then(async res => {
        self.user_data = res.data.data;
      })
    },
    orderAvailabilities(){
      this.user_data.availabilities.forEach(function(availability){
        availability.morning = []
        availability.afternoon = []
        availability.availableTimes.forEach(function(availableTime){
            if(parseInt(availableTime.startTime.substring(0,2)) < 5){
              availability.morning.push(availableTime.startTime + " - " + availableTime.endTime)
            }
            else{
              availability.afternoon.push(availableTime.startTime + " - " + availableTime.endTime)
            }
        })
        if(availability.morning.length === 0){
          availability.morning.push("N/A")
        }
        if(availability.afternoon.length === 0){
          availability.afternoon.push("N/A")
        }
      })
    },
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm Disable`,
        text: `Are you sure you want to disable this user?`,
        accept: this.deleteDetailHandler,
        acceptText: "Yes"
      })
    },
    deleteDetailHandler() {
      let tutorId = this.$route.params.userId
      let tutorData = {status : 'Disabled'}
      this.updateTutorById({tutorId, tutorData}).then(res => {  //can update both user and tutor
        this.$vs.notify({
          title: 'Delete',
          text: 'User disabled successfully',
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success'
        })
        this.$router.replace({name:'Users'})
      }).catch(err => {
        this.$vs.notify({
          title: 'Failure',
          text: 'Something went wrong.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    }
  },
  async mounted() {
      await this.getTutorDetails()
  }
}

</script>

<style lang="scss">
#avatar-col {
  width: 10rem;
}

#page-user-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: .8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width:370px) {
          display: block;
        }
      }
    }
  }
}

// #account-info-col-1 {
//   // flex-grow: 1;
//   width: 30rem !important;
//   @media screen and (min-width:1200px) {
//     & {
//       flex-grow: unset !important;
//     }
//   }
// }


@media screen and (min-width:1201px) and (max-width:1211px),
only screen and (min-width:636px) and (max-width:991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }

  // #account-manage-buttons {
  //   width: 12rem !important;
  //   flex-direction: column;

  //   > button {
  //     margin-right: 0 !important;
  //     margin-bottom: 1rem;
  //   }
  // }

}

  .imgfit {
    height : 110px;
    width: 110px;
    border-radius: 50%;
    margin-left: 5px;
    margin-right: 10px;
    object-fit: cover;
  }
</style>
